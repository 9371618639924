import styled from '@emotion/styled';
import React from 'react';

const StyledFooter = styled.div`
  width: 100%;
  min-height: 70vh;
`;

const Footer = () => {
  return <StyledFooter></StyledFooter>;
};

export default Footer;
