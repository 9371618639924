import React, { useLayoutEffect, useRef } from 'react';
import Layout from '../Common/Layout';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Hero from '../Common/Hero';
import MainNavLink from '../Common/MainNavLink';
import Footer from '../Common/Footer';

const Home = () => {
  gsap.registerPlugin(ScrollTrigger);
  const root = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const textElements = gsap.utils.toArray<HTMLElement>('.mainLink .label');

      textElements.forEach((text) => {
        gsap.to(text, {
          backgroundSize: '100%',
          ease: 'none',
          scrollTrigger: {
            trigger: text,
            start: 'center 80%',
            end: 'center 20%',
            scrub: true,
            // markers: true,
          },
        });
      });
    }, root); // <- scopes all selector text to the root element

    gsap.to('.logo', {
      transform: 'translateY(-50vw)',
      ease: 'none',
      scrollTrigger: {
        trigger: '.logo',
        start: 'center 50%',
        end: 'center 15%',
        scrub: true,
        // markers: true,
      },
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="wrapper" ref={root}>
      <Layout>
        <Hero />
        <div className="linkWrapper">
          <MainNavLink
            id="link1"
            label="about"
            hoverText="munky"
            link="#about"
          />
          <MainNavLink
            label="portfolio"
            hoverText="software dev"
            link="#portfolio"
          />
          <MainNavLink
            label="photography"
            hoverText="exploration"
            link="#photography"
          />
          <MainNavLink
            label="sandbox"
            hoverText="experiments"
            link="#experiment"
          />
          <MainNavLink label="connect" hoverText="hello :)" link="#connect" />
        </div>
        <Footer />
      </Layout>
    </div>
  );
};

export default Home;
