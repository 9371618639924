import React, { FC } from 'react';
import Palette from './Palette';
import styled from '@emotion/styled';

const StyledLink = styled.a`
  font-size: min(10vw, 10rem);
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 0.75;
  margin: 0;
  padding: 0;

  width: 100%;
  color: ${Palette.Link.Color};
  display: block;
  position: relative;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    text-transform: uppercase;

    padding: 2rem;

    border-bottom: 1px solid ${Palette.Link.Color};
  }

  .label {
    background: linear-gradient(
        to right,
        ${Palette.Link.Fill},
        ${Palette.Link.Fill}
      )
      no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 0%;
    transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;
  }

  .hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Palette.Link.Hover.BgColor};
    color: ${Palette.Link.Hover.Color};

    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transform-origin: center;
    transition: all cubic-bezier(0.1, 0.5, 0.5, 1) 0.4s;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    .hover {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
`;

interface IMainNavLink {
  label: string;
  hoverText: string;
  id?: string;
  link?: string;
}

const MainNavLink: FC<IMainNavLink> = (props) => {
  const { label, hoverText, link, ...restProps } = props;

  return (
    <StyledLink className="mainLink" href={link} {...restProps}>
      <span className="label">{label}</span>
      <span className="hover">{hoverText}</span>
    </StyledLink>
  );
};

export default MainNavLink;
