import React from 'react';
import styled from '@emotion/styled';
import HeroImage from '../Assets/hero.jpg';
import MunkyLogo from '../Assets/munky_logo.svg';
import Palette from './Palette';

const StyledHero = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${HeroImage});
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 3;
    background-color: rgba(${Palette.Hero.BgColorRGB}, 0.75);
    pointer-events: none;
    img {
      width: 100%;
      height: min(50vw, 200px);
      object-fit: contain;
      padding: 2vw;
      background: linear-gradient(
          to right,
          ${Palette.Link.Hover.BgColor},
          ${Palette.Link.Hover.BgColor}
        )
        no-repeat;
      -webkit-background-clip: border-box;
      background-clip: border-box;
      background-size: 100% 0%;
      transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.3s;
      background-position: center top;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 40vw;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to top,
      rgba(${Palette.Hero.BgColorRGB}, 1),
      rgba(${Palette.Hero.BgColorRGB}, 0)
    );
    z-index: 2;
  }
`;

const Hero = () => {
  return (
    <StyledHero>
      <div className="logo">
        <img src={MunkyLogo} />
      </div>
    </StyledHero>
  );
};

export default Hero;
