import React from 'react';
import Home from './Pages/Home';
import './App.css';

const App = () => (
  <div className="App">
    <Home />
  </div>
);

export default App;
