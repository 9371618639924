import React, { FC } from 'react';
import styled from '@emotion/styled';

const StyledLayout = styled.div`
  font-family: 'Poppins', sans-serif;
  padding: 0;
  min-height: 100vh;
  width: 100%;
`;

type Props = {
  children: string | JSX.Element | JSX.Element[] | React.ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

export default Layout;
