const Palette = {
  Link: {
    Color: 'rgba(0, 166, 251, 0.2)',
    Fill: 'rgba(0, 166, 251, 1)',
    BgColor: 'rgba(0, 0, 0, 0)',
    Hover: {
      Color: '#051923',
      BgColor: '#00a6fb',
    },
  },
  Hero: {
    BgColorRGB: '5, 25, 35',
  },
};

export default Palette;
